import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

export const getImpactIndicators = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'GET',
    url: `/oto/sales/getImpactOtoBrand`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
    },
  });
};
