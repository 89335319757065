<template>
  <div class="welcome-cards">
    <div v-for="(item, index) in cards" :key="index" @click="redirect(item)" class="welcome-cards__item">
      <div class="welcome-cards__icon">
        <img :src="require(`@/assets/img/icons/welcome/${item.icon}.svg`)" :alt="$t('360.actions.dialog.alert-icon')" />
      </div>

      <div class="welcome-cards__title">
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useMixpanelWelcome } from '@/utilities/mixpanel/welcome';

export default {
  name: 'WelcomeCard',
  data() {
    return {
      cards: [
        {
          title: this.$tc('welcome.cards.academy.title'),
          icon: 'graduation',
          link: '/oto-academy',
        },
        {
          title: this.$tc('welcome.cards.indicators.title'),
          icon: 'report',
          link: '/oto/engagement-report',
        },
        {
          title: this.$tc('welcome.cards.sales.title'),
          icon: 'money',
          link: '/oto/sales-report-new',
        },
        {
          title: this.$tc('welcome.cards.sellers.title'),
          icon: 'users',
          link: '/oto/sellers',
        },
        {
          title: this.$tc('welcome.cards.support.title'),
          icon: 'question',
          link: 'https://ajuda.otocrm.com.br/support/home',
        },
      ],
    };
  },
  methods: {
    redirect(item) {
      useMixpanelWelcome(item.link);

      if (item.title === 'Central de ajuda') {
        window.open(item.link, '_blank');
      } else {
        this.$router.push(item.link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-cards {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  &__item {
    width: 215px;
    height: 68px;
    border-radius: 8px;
    background-color: $color-white;
    box-shadow: 0 4px 7px 0 #00000012;
    cursor: pointer;

    transition: all 0.3s ease;

    display: grid;
    grid-template-columns: 1fr 2fr;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &__icon {
    background-color: $gray-200;
    border-radius: 8px;
    color: $gray-800;

    display: grid;
    place-content: center;
    margin: 10px 12px 10px 9px;
  }

  &__title {
    align-self: center;

    p {
      font-size: 14px;
      font-weight: 600;
      color: $gray-800;
      margin-bottom: 0;
    }
  }
}
</style>
