<template>
  <div class="welcome">
    <home-banner />

    <div v-show="isSuperAdmin" class="welcome__impact">
      <div />

      <impact-indicator />
    </div>

    <div class="welcome__container">
      <welcome-cards />

      <base-card-v2>
        <div id="updates"></div>
      </base-card-v2>
    </div>
  </div>
</template>

<script>
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
import HomeBanner from '@/features/HomeBanner/components/Banner.vue';
import WelcomeCards from '@/components/welcome/_molecules/WelcomeCards';
import ImpactIndicator from '@/features/OtoBrandImpact/components/Impact/ImpactIndicator.vue';
import { profileType } from '@/utilities/constants';

export default {
  name: 'Welcome',
  components: {
    HomeBanner,
    WelcomeCards,
    ImpactIndicator,
  },
  data() {
    return {
      noticeable: 'tQ4wrFCK01bxtvkYxgd2',
    };
  },
  computed: {
    isSuperAdmin() {
      return this.$store.state.auth.user?.profile === profileType.context;
    },
    termsOfService() {
      return this.$store.state.auth.user?.termsOfService;
    },
  },
  mounted() {
    useMixpanelConfig('view_home');

    if (!this.$store?.getters?.isLoggedIn) return;

    this.$nextTick(function () {
      window.noticeable.render('widget', this.noticeable, { selector: '#updates' });
    });
  },
  unmounted() {
    window.noticeable.destroy('widget', this.noticeable);
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  margin-top: -30px;

  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  &__impact {
    margin: 16px 0;
    display: grid;
    grid-template-columns: 1fr 443px;
    gap: 16px;

    & div:first-child {
      background-color: #bbbbbb;
      border-radius: 8px;
      box-shadow: 0 2px 5px 0 #0000000d;
    }
  }
}

#updates {
  #global-container {
    .wrapper {
      width: 100% !important;
    }
  }
}
</style>
