
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import i18n from '@/i18n';
import { LoadingBySegments, SelectedFilterListOptions, SelectedSegment } from '@/features/SalesReportNew/models';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner.vue';
import vClickOutside from 'click-outside-vue3';
import { cloneDeep } from 'lodash';

export default defineComponent({
  components: {
    BaseLoader,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const store = useStore();
    const optionsList = ref<SelectedFilterListOptions[]>([]);
    const inputValue = ref([]);
    const selectedValues = ref([]);
    const allChecked = ref<boolean>(false);
    const valueSearch = ref<string>('');
    const isDropdownOpened = ref<boolean>(false);
    const wasApplied = ref<boolean>(false);

    // computed
    const getOptionsFromSelectedSegment = computed<SelectedFilterListOptions[]>(
      () => store.getters['salesReportNew/getOptionsFromSelectedSegment'],
    );

    const disabledButton = computed(() => {
      return !(hasCheckedItem.value.length || selectedValues.value.length);
    });

    const getSelectedSegment = computed<string>(() => store.getters['salesReportNew/getSelectedSegment']);
    const getDefaultList = computed<SelectedFilterListOptions[]>(() => store.getters['salesReportNew/getDefaultList']);

    const isAllChecked = computed(() => optionsList.value.every(item => item.isChecked));
    const hasCheckedItem = computed(() => updatedOptions.value.filter(item => item.isChecked));
    const updatedOptions = computed(() =>
      getDefaultList.value.map(item => {
        const correspondingItem = selectedValues.value.find(selectedItem => selectedItem.value === item.value);
        if (correspondingItem) {
          return { ...item, isChecked: correspondingItem.isChecked };
        }
        return item;
      }),
    );

    const selectedItemsValue = computed<string>(() => {
      const filterByChecked = updatedOptions.value.filter(item => item.isChecked);

      const textOfTheWordSelected =
        getSelectedSegment.value === SelectedSegment.STORE || getSelectedSegment.value === SelectedSegment.CAMPAIGN
          ? '_selected-f'
          : '_selected-m';

      const pluralOrSingularSelectedText =
        filterByChecked.length === 1
          ? i18n.global.tc(`${textOfTheWordSelected}`, 1)
          : i18n.global.tc(`${textOfTheWordSelected}`, 2);

      return !filterByChecked.length
        ? i18n.global.tc('_to-type', 2)
        : `${filterByChecked.length} ${pluralOrSingularSelectedText}`;
    });

    const allSelectedText = computed<string>(() => {
      const textOfTheWordSelected =
        getSelectedSegment.value === SelectedSegment.STORE || getSelectedSegment.value === SelectedSegment.CAMPAIGN
          ? '_all-f'
          : '_all';
      return textOfTheWordSelected;
    });

    const isLoadingAllFilters = computed<LoadingBySegments>(
      () => store.getters['salesReportNew/getIsLoadingAllFilters'],
    );

    const getPreset = computed<string>(() => store.getters['salesReportNew/getSelectedSegment']);

    const loadingSelect = computed(() => {
      if (getPreset.value === 'store') return isLoadingAllFilters.value.isLoadingStores;
      if (getPreset.value === 'seller') return isLoadingAllFilters.value.isLoadingSellers;
      if (getPreset.value === 'campaign') return isLoadingAllFilters.value.isLoadingCampaigns;
      if (getPreset.value === 'cluster') return isLoadingAllFilters.value.isLoadingClusters;
      return false;
    });

    // action
    const updateCheckedStatus = (updatedArray: any) =>
      store.dispatch('salesReportNew/setUpdateCheckedStatus', updatedArray);
    const setApplyFilters = options => store.dispatch('salesReportNew/setApplyFilters', options);
    const sendSelectedSegment = () => store.dispatch('salesReportNew/setSelectedSegement', getSelectedSegment.value);
    const resetDefaultList = (options: SelectedFilterListOptions[]) =>
      store.dispatch('salesReportNew/updateDefaultList', options);
    const resetSegments = () => store.dispatch('salesReportNew/setResetSegmentsIds');

    const getUpdatedCheckedOptions = (isChecked: boolean): SelectedFilterListOptions[] =>
      optionsList.value?.map(option => {
        const match = updatedOptions.value.find(updated => updated.value === option.value);
        return match ? { ...option, isChecked } : option;
      }) || [];

    const applyUpdatedSelections = (
      optionsListValue: SelectedFilterListOptions[],
      updatedSelectedValues: SelectedFilterListOptions[],
    ) => {
      optionsList.value = optionsListValue;
      selectedValues.value = updatedSelectedValues;
    };

    const selectAllOptions = () => {
      if (allChecked.value) {
        const checkedOptions = getUpdatedCheckedOptions(true);
        const checkedItems = updatedOptions.value.filter(item => item.isChecked);
        const allCheckedValues = [...checkedOptions, ...checkedItems];
        applyUpdatedSelections(checkedOptions, allCheckedValues);
        inputValue.value = selectedValues.value.map(item => item.label);
      } else {
        const uncheckedOptions = getUpdatedCheckedOptions(false);
        const uncheckedValues = new Set(uncheckedOptions.map(item => item.value));
        const additionalItems = updatedOptions.value.filter(item => !uncheckedValues.has(item.value));
        const allItems = [...uncheckedOptions, ...additionalItems];
        applyUpdatedSelections(uncheckedOptions, allItems);
        inputValue.value = [];
      }
    };

    const updateData = () => {
      isDropdownOpened.value = !isDropdownOpened.value;
      resetFilters();
      selectedValues.value = [];
    };

    const clearAllOptions = () => {
      resetSegments();
      applyFilters();
    };

    watch(getSelectedSegment, () => {
      inputValue.value = [];
    });

    watch(getOptionsFromSelectedSegment, () => {
      optionsList.value = getOptionsFromSelectedSegment.value;
    });

    watch(loadingSelect, () => {
      sendSelectedSegment();
    });

    watch(getDefaultList, () => getDefaultList.value.length && updateCheckAllCheckbox());

    const updateCheckAllCheckbox = () => {
      allChecked.value = updatedOptions.value.every(item => item.isChecked);
    };

    const updateCheckedItem = (newValue: SelectedFilterListOptions) => {
      if (!newValue) return;

      const existingItem = selectedValues.value.find(item => item.value === newValue.value);

      if (existingItem) existingItem.isChecked = newValue.isChecked;
      else selectedValues.value.push(newValue);

      updateCheckAllCheckbox();
    };

    const filterMethod = (searchValue: string) => {
      valueSearch.value = searchValue;

      if (searchValue === '') {
        optionsList.value = cloneDeep(updatedOptions.value).map(originalItem => {
          const markedItem = selectedValues.value.find(marked => marked.value === originalItem.value);
          if (markedItem) {
            return { ...originalItem, isChecked: markedItem.isChecked };
          }
          return originalItem;
        });
        return;
      }

      const filteredList = cloneDeep(updatedOptions.value).filter(item =>
        item.label.toLowerCase().includes(searchValue.toLowerCase()),
      );

      optionsList.value = filteredList;
    };

    const resetFilters = () => {
      valueSearch.value = '';
      optionsList.value = cloneDeep(getDefaultList.value);
      updateCheckAllCheckbox();

      if (!selectedValues.value.length) return;

      const selectedValuesIds = selectedValues.value.map(item => String(item.value));
      const updatedOptionsList = optionsList.value.map(item => {
        if (selectedValuesIds.includes(String(item.value))) {
          return {
            ...item,
            isChecked: false,
          };
        }
        return item;
      });

      wasApplied.value ? (optionsList.value = getDefaultList.value) : (optionsList.value = updatedOptionsList);
      resetDefaultList(optionsList.value);
    };

    onMounted(() => (optionsList.value = getOptionsFromSelectedSegment.value));

    const applyFilters = () => {
      wasApplied.value = true;
      optionsList.value = updatedOptions.value;

      valueSearch.value = '';

      updateCheckedStatus(optionsList.value);
      setApplyFilters(optionsList.value);

      selectedValues.value = [];
    };
    return {
      optionsList,
      inputValue,
      allChecked,
      updatedOptions,
      selectedItemsValue,
      valueSearch,
      filterMethod,
      resetFilters,
      selectAllOptions,
      isAllChecked,
      selectedValues,
      getSelectedSegment,
      getOptionsFromSelectedSegment,
      applyFilters,
      disabledButton,
      allSelectedText,
      isLoadingAllFilters,
      loadingSelect,
      updateCheckedItem,
      updateData,
      clearAllOptions,
      isDropdownOpened,
    };
  },
});
