import { getImpactIndicators } from '../services';
import { ImpactIndicatorsFormattedResponse, ImpactIndicatorsResponse } from '@/features/OtoBrandImpact/models';

export const fetchOtoImpactIndicators = (): Promise<ImpactIndicatorsFormattedResponse> =>
  getImpactIndicators().then(result => formatResponse(result?.data));

const formatResponse = (response: ImpactIndicatorsResponse): ImpactIndicatorsFormattedResponse => ({
  success: response?.success,
  errors: response?.errors,
  data: {
    revenueBrand: {
      key: response?.data.revenue_brand.key,
      name: response?.data.revenue_brand.name,
      tooltip: response?.data.revenue_brand.tooltip,
      value: response?.data.revenue_brand.value,
    },
    revenueOto: {
      key: response?.data.revenue_oto.key,
      name: response?.data.revenue_oto.name,
      tooltip: response?.data.revenue_oto.tooltip,
      value: response?.data.revenue_oto.value,
    },
    revenueOtoPerc: {
      key: response?.data.revenue_oto_perc.key,
      name: response?.data.revenue_oto_perc.name,
      tooltip: response?.data.revenue_oto_perc.tooltip,
      value: response?.data.revenue_oto_perc.value,
    },
  },
});
