export type ErrorType = {
  code: string;
  message: string;
};

export type TableMetricsData = {
  key: string;
  name: string;
  type: string;
  preview: boolean;
};

export type EditTableResponse = {
  success: boolean;
  errors: ErrorType[];
  tableMetrics: FormattedData[];
  ordering: TableMetricsData[];
  warnings: object[];
};

export type DataType = {
  key: string;
  name: string;
};

export type FormattedData = {
  key: string;
  data: TableMetricsData[];
};

export type PostEditColumnsResponse = {
  data: {
    ConfigID: number;
  };
  errors: ErrorType[];
  success: boolean;
};

export type PostEditColumnsPreset = 'STORE' | 'PROACTIVE_CONTACTS' | 'STORE_LOCATION' | 'SELLER' | 'DATE' | 'CAMPAIGN';

export enum SelectedPreset {
  DATE = 'DATE',
  STORE = 'STORE',
  SELLER = 'SELLER',
  CAMPAIGN = 'CAMPAIGN',
  STORE_LOCATION = 'STORE_LOCATION',
  PROACTIVE_CONTACTS = 'PROACTIVE_CONTACTS',
}
